import axios from '@/http/axios'

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getFinancialYears (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/financial-years${queries}`,
    method: 'get'
  })
}

export function insertFinancialYear () {

  return axios({
    url: 'v1/admin/financial-years',
    method: 'post'
  })
}

export function switchFinancialYear (id) {

  return axios({
    url: `v1/admin/financial-years/${id}/switch`,
    method: 'post'
  })
}
